import { Box, Typography, useTheme } from "@mui/material"
import React from "react"
import IndustrialAutomation from "../components/IndustrialAutomation"
import InformationSecurity from "../components/InformationSecurity"
import MachineLearning from "../components/MachineLearning"
import SystemArchitecture from "../components/SystemArchitecture"

interface ServicesProps {}

const Services: React.FC<ServicesProps> = ({}) => {
  const theme = useTheme()
  return (
    <Box className="services">
      <Box className="services-title" display="grid">
        <Typography
          alignSelf="center"
          variant="h3"
          fontWeight={"bold"}
          mt="2rem"
          pb="5rem"
        >
          WHAT WE DO
        </Typography>
      </Box>
      <Box className="services-tiles">
        <IndustrialAutomation />
        <SystemArchitecture />
        <MachineLearning />
        <InformationSecurity />
      </Box>
    </Box>
  )
}

export default Services
