import { Box, Typography } from "@mui/material"
import React from "react"
import map from "../images/MAP_update-CompleteLayout_Robotics.png"
import LocationOnIcon from '@mui/icons-material/LocationOn';

interface MapProps {}

const Map: React.FC<MapProps> = () => {
  return (
    <Box
      className="map"
    >
        <Typography
          className="map-heading-text"
          variant="h3"
          fontWeight="bold"
          color="primary"
          textAlign="left"
        >
          UP ROBOTICS GROUP
        </Typography>
        <br />
        <Box className="offices" textAlign="left" pl="2rem">
          <Typography variant="h4" pb="1rem" color="secondary">
            Our offices
          </Typography>
          <Typography variant="h6" fontWeight="bold" color="primary" pl="0.5rem">
            PULA
          </Typography>
          <Typography variant="subtitle1" color="secondary" pl="0.5rem" display="flex" justifyItems={"center"} pb="1rem">
            <LocationOnIcon height="1em"></LocationOnIcon><span>Monte Bici 9, 52100 Pula, Croatia</span>
          </Typography>
          <Typography variant="h6" fontWeight="bold" color="primary" pl="0.5rem">
            ZAGREB
          </Typography>
          <Typography variant="subtitle1" color="secondary" pl="0.5rem" display="flex" justifyItems={"center"} pb="1rem">
          <LocationOnIcon height="1em"></LocationOnIcon><span>Jaruščica 9b, 10020 Zagreb, Croatia</span>
          </Typography>
          <Typography variant="h6" fontWeight="bold" color="primary" pl="0.5rem">
            BELGRADE
          </Typography>
          <Typography variant="subtitle1" color="secondary" pl="0.5rem" display="flex" justifyItems={"center"} pb="1rem">
          <LocationOnIcon height="1em"></LocationOnIcon><span>Nehruova 80, 11070 Belgrade, Serbia</span>
          </Typography>
        </Box>
        <img className="map-image" src={map} alt="map" width="800px"></img>
    </Box>
  )
}

export default Map
