import { Typography } from "@mui/material"
import Box from "@mui/material/Box"
import React from "react"
import bmwlogo from "../images/clients/BMW_White_Logo.svg"
import fordlogo from "../images/clients/ford-1-logo-png-transparent.png"
import worldbanklogo from "../images/clients/logo-wb-header-en.png"
import iptelogo from "../images/clients/ipte-logo.svg"
import baumitlogo from "../images/clients/Baumit-logo.png"
import piklogo from "../images/clients/logo_pik_vrbovec_small.png"
import ekopluslogo from "../images/clients/ekoplus-full.png"
import zigglogo from "../images/clients/ziggpro-logo.png"

interface ClientSpaceProps {}

const ClientSpace: React.FC<ClientSpaceProps> = () => {
  return (
    <Box bgcolor="#353535" textAlign={"center"} py="20px">
      <Typography variant="h4" color="white" fontWeight={"bold"}>
        Who we work with
      </Typography>
      <Box
        className="clients"
      > 
        <div>
          <img
            src={bmwlogo}
            width={"150px"}
            height="auto"
            alt="logo1"
            className="client-logo"
          ></img>
        </div>
        <div>
          <img
            src={worldbanklogo}
            width={"150px"}
            height="auto"
            alt="logo3"
            className="client-logo-invert"
          ></img>
        </div>
        <div>
          <img
            src={fordlogo}
            width={"150px"}
            height="auto"
            alt="logo2"
            className="client-logo"
          ></img>
        </div>
        <div>
          <img
            src={iptelogo}
            width={"150px"}
            height="auto"
            alt="logo4"
            className="client-logo"
          ></img>
        </div>
        <div>
          <img
            src={piklogo}
            width={"150px"}
            height="auto"
            alt="logo6"
            className="client-logo"
          ></img>
        </div>
        <div>
          <img
            src={ekopluslogo}
            width={"150px"}
            height="auto"
            alt="logo7"
            className="client-logo"
          ></img>     
        </div>
        <div>
          <img
            src={zigglogo}
            width={"150px"}
            height="auto"
            alt="logo8"
            className="client-logo"
          ></img>
        </div>
        <div>
          <img
            src={baumitlogo}
            width={"150px"}
            height="auto"
            alt="logo5"
            className="client-logo-invert"
          ></img>
        </div>
      </Box>
    </Box>
  )
}

export default ClientSpace
