import { Box, Typography } from "@mui/material"
import React from "react"
import image from "../images/SERVICES_ICON-AI-framed.svg"

interface MachineLearningProps {}

const MachineLearning: React.FC<MachineLearningProps> = ({}) => {
  return (
    <div>
      <Box display="grid" gridTemplateColumns={"2fr 1fr"}>
        <Box position={"relative"}>
          <Typography
            className="service-name-left"
            variant="h5"
            fontWeight={"medium"}
            sx={{ position: "absolute", top: "50px", right: "50px" }}
          >
            MACHINE LEARNING
          </Typography>
          <div className="line-left"></div>
        </Box>
        <img
          className="service-icon"
          src={image}
          alt="machine-learning"
          height="200px"
          width="auto"
        ></img>
      </Box>
    </div>
  )
}

export default MachineLearning
