import { Box, Typography } from "@mui/material"
import React from "react"
import image from "../images/SERVICES_ICON-ARCHITECTURE-framed.svg"

interface SystemArchitectureProps {}

const SystemArchitecture: React.FC<SystemArchitectureProps> = ({}) => {
  return (
    <Box display="grid" gridTemplateColumns={"1fr 2fr"} textAlign="left">
      <Box display="grid" justifyItems={"end"}>
        <img
          className="service-icon"
          src={image}
          alt="system-architecture"
          height="200px"
          width="auto"
        ></img>
      </Box>
      <Box position={"relative"}>
        <div className="line-right"></div>
        <Typography
          className="service-name-right"
          variant="h5"
          fontWeight={"medium"}
          sx={{ position: "absolute", top: "50px", left: "50px" }}
        >
          SYSTEM ARCHITECTURE
        </Typography>
      </Box>
    </Box>
  )
}

export default SystemArchitecture
