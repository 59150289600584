import { Box, Typography, useTheme, Container } from "@mui/material"
import React from "react"
import Obfuscate from "react-obfuscate"

interface ContactProps {}

const Contact: React.FC<ContactProps> = () => {
  const theme = useTheme()
  return (
    <Box bgcolor="white" paddingY="5rem" height="40vh" display="grid" justifyItems="center" alignItems="center">
      <Container maxWidth="xl">
        <Typography
          variant="h4"
          color={theme.palette.primary.main}
          paddingBottom="2rem"
          marginX="0.5rem"
          fontWeight="bold"
        >
          Interested in working with us?
        </Typography>
        <Typography fontSize="1.5rem" display="inline">Reach out to us at </Typography>
        <Typography fontSize="1.5rem" color="secondary" display="inline">
          <Obfuscate className="mail-link" email="mailto:office@up.hr">office@up.hr</Obfuscate>
          </Typography>
      </Container>
    </Box>
  )
}

export default Contact
