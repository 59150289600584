import React from "react"
import { Box, Button, Container, Typography } from "@mui/material"
import logo from "../images/up-novi.png"

const LandingPage = () => {
  return (
    <Box className="landing-wrapper">
      <Container maxWidth="xl">
        <Box
          className="landing"
        >
          <Box
            className="landing-grid-text"
            justifySelf="start"
            alignSelf="center"
            textAlign="left"
          >
            <Box
              color="#353535"
              marginLeft={"1em"}
              marginBottom={"3rem"}
            >
              <Typography variant="h3" fontWeight={"medium"}>
                AUTOMATING
              </Typography>
              <Typography variant="h3" fontWeight={"bold"} color="primary">
                THE FUTURE
              </Typography>
              <Typography variant="h3" fontWeight={"light"}>
                SO YOU CAN FOCUS ON
              </Typography>
              <Typography variant="h3" fontWeight={"bold"} color="primary">
                WHAT MATTERS
              </Typography>
            </Box>
          </Box>
          <Box className="landing-grid-logo">
            <img src={logo} alt="logo" height="550px" width="auto"></img>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default LandingPage
