import { Box, Typography } from "@mui/material"
import React from "react"
import image from "../images/SERVICES_ICON-SECURITY-framed.svg"

interface InformationSecurityProps {}

const InformationSecurity: React.FC<InformationSecurityProps> = ({}) => {
  return (
    <div>
      <Box display="grid" gridTemplateColumns={"1fr 2fr"} textAlign="left">
        <Box display="grid" justifyItems={"end"}>
          <img
            className="service-icon"
            src={image}
            alt="industrial automation"
            height="200px"
            width="auto"
            color="white"
          ></img>
        </Box>
        <Box position={"relative"}>
          <div className="line-right"></div>
          <Typography
            className="service-name-right"
            variant="h5"
            fontWeight={"medium"}
            sx={{ position: "absolute", top: "50px", left: "50px" }}
          >
            INFORMATION SECURITY
          </Typography>
        </Box>
      </Box>
    </div>
  )
}

export default InformationSecurity
