import { Box, Container, Typography } from "@mui/material"
import React from "react"
import logo from "../images/white_logo_UPPROBOTICS-05.svg"
import Obfuscate from "react-obfuscate"

interface FooterProps {}

const Footer: React.FC<FooterProps> = ({}) => {
  return (
    <Box className="footer">
      <Container maxWidth="xl">
        <Box display="flex" justifyContent="center" alignItems="center" py="2rem" gap="4rem">
          <Box>
            <img src={logo} alt="logo" className="footer-logo"></img>

          </Box>
          <Box textAlign="left" color="white" pb="1rem" >
            <Typography variant="h6" fontWeight={"bold"} >UP Robotics d.o.o</Typography>
            <Typography fontSize="0.9rem" fontWeight={"bold"} ml="1rem">Address</Typography>
            <Typography fontSize="0.9rem" ml="1rem">Šenoina ulica 28</Typography>
            <Typography fontSize="0.9rem" ml="1rem">52100 Pula, Croatia</Typography>
            <Typography fontSize="0.9rem" fontWeight={"bold"} ml="1rem" pt="0.5rem">Contact Phone</Typography>
            <Typography fontSize="0.9rem" ml="1rem">
              <Obfuscate element="span" style={{ textAlign: 'left' }}>
                +385 52 506 520
              </Obfuscate>
            </Typography>
          </Box>
        </Box>
        <Typography color="white"fontSize="0.85rem" pb="1rem">© 2022 UP Robotics </Typography>
      </Container>
    </Box>
  )
}

export default Footer
