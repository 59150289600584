import { createTheme, ThemeProvider } from "@mui/material"
import { Shadows } from "@mui/material/styles/shadows"
//import { useState, useEffect } from "react"
import "./App.css"
import Map from "./components/Map"
import ClientSpace from "./containers/ClientSpace"
import Contact from "./containers/Contact"
import Footer from "./containers/Footer"
import LandingPage from "./containers/Landing"
import Services from "./containers/Services"

const theme = createTheme({
  palette: {
    primary: {
      main: "#3C6E71",
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#353535",
    },
  },
  shadows: ["none"] as any as Shadows,
  typography: {
    fontFamily: ['"Montserrat"'] as any,
  },
})

function App() {
  // const [showButton, setShowButton] = useState(false);

  // useEffect(() => {
  //   window.addEventListener("scroll", () => {
  //     if (window.pageYOffset > 500) {
  //       setShowButton(true);
  //     } else {
  //       setShowButton(false);
  //     }
  //   });
  // }, []);

  // // This function will scroll the window to the top 
  // const scrollToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: 'smooth' // for smoothly scrolling
  //   });
  //};
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <LandingPage />
        <Services />
        <Map />
        <ClientSpace />
        <Contact />
        <Footer />

        {/* {showButton && (
        <button onClick={scrollToTop} className="back-to-top">
          &#8679;
        </button>
        )} */}
      </div>
    </ThemeProvider>
  )
}

export default App
