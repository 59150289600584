import { Box, Typography } from "@mui/material"
import React from "react"
import image from "../images/SERVICES_ICON-ROBOTIC-framed.svg"

interface IndustrialAutomationProps {}

const IndustrialAutomation: React.FC<IndustrialAutomationProps> = ({}) => {
  return (
    <Box display="grid" gridTemplateColumns={"2fr 1fr"}>
      <Box position={"relative"}>
        <Typography
          className="service-name-left"
          variant="h5"
          fontWeight={"medium"}
          sx={{ position: "absolute", top: "50px", right: "50px" }}
        >
          INDUSTRIAL AUTOMATION
        </Typography>
        <div className="line-left"></div>
      </Box>
      <img
        className="service-icon"
        src={image}
        alt="industrial automation"
        height="200px"
        width="auto"
      ></img>
    </Box>
  )
}

export default IndustrialAutomation
